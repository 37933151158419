import { inject } from "mobx-react";
import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import type { Store } from "@inferno/renderer-shared-core";
import { CalendarIcon } from "../../components/icons/CalendarIcon.component";
import { TrendingIcon } from "../../components/icons/TrendingIcon.component";
import { ShareButtons } from "../../components/integrations/ShareButtons.component";
import type { ChartsListFragment } from "@ihr-radioedit/inferno-webapi";
import { reverseRoute } from "@inferno/renderer-shared-core";
import { Container, Heading, MagicLink } from "../../ui";
import type { PageBlockInterface } from "../Block.component";
import "./ChartList.style.scss";
import { ChartListItem } from "./ChartListItem.component";

interface ChartListProps extends PageBlockInterface {
  list: ChartsListFragment;
  chartTitle: string;
  chartSlug: string;
  showAsWidget?: boolean;
  store?: Store;
}

export const ChartList = inject("store")(
  ({ list, block, chartSlug, showAsWidget, store, chartTitle }: ChartListProps) => {
    if (!store) {
      return null;
    }

    useEffect(() => {
      store.storeBlock({ ...block, resolved: { list, chartTitle } });
    });

    const { t } = useTranslation();
    const { request, site } = store;

    // Widget view shows fewer items
    const songs = showAsWidget ? list.songs.slice(0, 3) : list.songs;

    const path =
      reverseRoute(site, "chart_detail", { chart_slug: chartSlug, list_slug: list.slug }) ||
      `/charts/${chartSlug}/${list.slug}`;

    const shareUrl = site.getShareUrl(request, path);

    return (
      <Fragment>
        <header className="content-header chart-header">
          <Heading level={showAsWidget ? 3 : 1}>
            {chartTitle}
            {!showAsWidget ? `- ${list.title}` : null}
          </Heading>
        </header>

        {!showAsWidget ? <ShareButtons title={list.title} url={shareUrl} className="chart-share" /> : null}

        <Container className="component-chartlist">
          <header className="track-meta-header">
            <div className="movement">&nbsp;</div>
            <div className="thumb">&nbsp;</div>
            <div className="headers">
              <span className="weeks">
                <CalendarIcon /> {t("heading_weeks_on_chart")}
              </span>
              <span className="peak">
                <TrendingIcon /> {t("heading_peak_on_chart")}
              </span>
            </div>
          </header>
          {songs.map((s, i) => (
            <ChartListItem className={showAsWidget ? "widget" : ""} key={i} item={s} />
          ))}

          {showAsWidget ? (
            <div className="view-full-chart">
              <MagicLink to={path}>{t("view_full_chart")} &raquo;</MagicLink>
            </div>
          ) : null}
        </Container>
      </Fragment>
    );
  },
);

export default ChartList;
