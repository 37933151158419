import { CalendarIcon } from "../../components/icons/CalendarIcon.component";
import { ChartArrow } from "../../components/icons/ChartArrow.component";
import { TrendingIcon } from "../../components/icons/TrendingIcon.component";
import { ResponsiveImage } from "../../components/ResponsiveImage.component";
import type { ChartListSongFragment } from "@ihr-radioedit/inferno-webapi";
import type { SrcSet } from "@inferno/renderer-shared-core";
import { placeholderImage } from "@ihr-radioedit/inferno-core";
import { MagicLink } from "../../ui";
import "./ChartListItem.style.scss";
import { RESPONSIVE_IMAGE } from "../../ui/constants";

interface ChartListItemProps {
  key: number;
  item: ChartListSongFragment;
  className?: string;
}

const ChartListItemThumb = ({ item }: { item: ChartListSongFragment }) => {
  const trackImg = item.image || placeholderImage;
  const imgSize = RESPONSIVE_IMAGE.chartListItemThumb;
  const defaultSrc = `${trackImg}?ops=gravity("center"),ratio(1,1),fit(${imgSize},${imgSize}),quality(80)`;
  const srcset: SrcSet[] = trackImg
    ? [
        {
          url: defaultSrc,
          descriptor: "1x",
        },
        {
          url: `${trackImg}?ops=gravity("center"),ratio(1,1),fit(${imgSize * 2},${imgSize * 2}),quality(80)`,
          descriptor: "2x",
        },
      ]
    : [];
  return (
    <ResponsiveImage src={defaultSrc} srcset={srcset} initialWidth={imgSize} initialHeight={imgSize} alt={item.title} />
  );
};

export const ChartListItem = ({ item, className }: ChartListItemProps) => {
  return (
    <figure className={`component-chartlist-item with-counter ${className}`}>
      <div className="track-movement">
        <div className="counter" />
        {item.delta && item.delta < 0 ? <ChartArrow direction="down" /> : null}
        {item.delta && item.delta > 0 ? <ChartArrow direction="up" fill="#62AA7B" /> : null}
      </div>
      {item.url ? (
        <MagicLink to={item.url}>
          <ChartListItemThumb item={item} />
        </MagicLink>
      ) : (
        <ChartListItemThumb item={item} />
      )}
      <figcaption>
        {item.url ? (
          <MagicLink aria-label={`${item.title}`} className="track-title" to={item.url}>
            {item.title}
          </MagicLink>
        ) : (
          <span className="track-title">{item.title}</span>
        )}
        {item.url ? (
          <MagicLink aria-label={`${item.artist}`} className="track-artist" to={item.url}>
            {item.artist}
          </MagicLink>
        ) : (
          <span className="track-artist">{item.artist}</span>
        )}
        <div className="track-meta">
          <span>
            <CalendarIcon /> {item.timeOnChart}
          </span>

          <span>
            <TrendingIcon /> {item.peak}
          </span>
        </div>
      </figcaption>
    </figure>
  );
};
